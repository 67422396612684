import Head from "next/head";
import { useEffect, useState } from "react";

import AffiliateCarousel from "../components/AffiliateCarousel";
import WordCountInfoPanel from "../components/WordCountInfoPanel";
import WordDensityTable from "../components/WordDensityTable";
import WordBadge from "../components/WordBadge";
import DocumentFileSelector from "../components/DocumentFileSelector";
import AdSenseBanner from "../components/AdSenseBanner";

import { remoteConfig, getAffiliateConfig } from "../lib/firebaseRemoteConfig";
import { useAdControllerContext } from "../contexts/AdControllerContext";

import {
  getBooleanLocalConfig,
  setBooleanLocalConfig,
  WORD_DENSITY_EXCLUDE_STOP_WORDS,
} from "../lib/localConfig";

import { useThDictionary, useThStopWords } from "./api/dictionary";
import NewMmTokenizer from "../nlp/tokenize/newmm";
import {
  wordCount,
  READING_SPEED_WPM,
  SPEAKING_SPEED_WPM,
} from "../nlp/countword/countword";

import { logAppUsage, logAffiliateClickEvent } from "../lib/firebaseAnalytic";

import styles from "../styles/IndexPage.module.css";

export default function IndexPage() {
  const [wordDensityExcludeStopWords, setWordDensityExcludeStopWords] =
    useState(false);
  const [affiliates, setAffiliates] = useState(null);
  const [showAffiliate] = useAdControllerContext();
  const [logUserTyping, setLogUserTyping] = useState(true);
  const [content, setContent] = useState("");
  const [tokenizer, setTokenizer] = useState(null);
  const [wordCounter, setWordCounter] = useState({
    wordCount: 0,
    charCount: 0,
    readingTime: 0,
    speakingTime: 0,
    words: [],
    wordDensity: [],
  });
  const {
    words: dictionary,
    isLoading: isDictionaryLoading,
    isError: isDictionaryError,
  } = useThDictionary();
  const {
    words: stopWords,
    isLoading: isStopWordsLoading,
    isError: isStopWordsError,
  } = useThStopWords();

  const onClearTextInputClick = (e) => {
    e.preventDefault();
    setContent("");
    logAppUsage(e);
    setLogUserTyping(true);
  };

  const onTextInputChange = (e) => {
    if (logUserTyping) {
      logAppUsage(e);
      setLogUserTyping(false);
    }
    setContent(e.target.value);
  };

  const onExcludeStopwirdSwitchChange = (e) => {
    logAppUsage(e, "configuration_changed");
    setBooleanLocalConfig(WORD_DENSITY_EXCLUDE_STOP_WORDS, e.target.checked);
    setWordDensityExcludeStopWords(e.target.checked);
  };

  const onAffiliateClick = (e) => {
    logAffiliateClickEvent(e);
  };

  const onContentReady = (e, c) => {
    logAppUsage(e);
    setContent(c);
  };

  useEffect(() => {
    setWordDensityExcludeStopWords(
      getBooleanLocalConfig(WORD_DENSITY_EXCLUDE_STOP_WORDS, false)
    );
  }, []);

  useEffect(() => {
    remoteConfig(() => {
      setAffiliates(getAffiliateConfig());
    }, true);
  }, [showAffiliate]);

  useEffect(() => {
    if (dictionary !== undefined && dictionary != null) {
      if (tokenizer == null) {
        console.log("Update dictionary : ", dictionary.length);
        setTokenizer(new NewMmTokenizer(dictionary));
      }
    }
  }, [dictionary, tokenizer]);

  useEffect(() => {
    if (tokenizer) {
      setWordCounter(
        wordCount(
          content,
          tokenizer,
          wordDensityExcludeStopWords ? stopWords : []
        )
      );
    }
  }, [content, tokenizer, stopWords, wordDensityExcludeStopWords]);

  return (
    <div className="container">
      <Head>
        <title>
          เครื่องมือนับคำออนไลน์ภาษาไทยและภาษาอังกฤษ | {process.env.appName}
        </title>
        <meta
          name="description"
          content="เครื่องมือนับคำออนไลน์ ทำงานได้กับภาษาไทยและภาษาอังกฤษ ใช้ได้ฟรี ที่จะช่วยนับจำนวนคำและตัวอักษร ได้อย่างแม่นยำและรวดเร็ว ให้งานเขียนของคุณง่ายขึ้น"
        />
        <meta name="keywords" content="นับคำ, นับคำภาษาไทย, ความหนาแน่นคำ" />
        <meta
          name="og:title"
          content={`เครื่องมือนับคำออนไลน์ภาษาไทยและภาษาอังกฤษ | ${process.env.appName}`}
        />
        <meta
          name="og:description"
          content="เครื่องมือนับคำออนไลน์ ทำงานได้กับภาษาไทยและภาษาอังกฤษ ใช้ได้ฟรี ที่จะช่วยนับจำนวนคำและตัวอักษร ได้อย่างแม่นยำและรวดเร็ว ให้งานเขียนของคุณง่ายขึ้น"
        />
      </Head>

      <main className="w-100">
        <div className="mt-2 mb-1 card">
          <div className="px-4 py-2 my-2 text-center">
            <h1 className="h4">
              เครื่องมือนับคำออนไลน์ภาษาไทยและภาษาอังกฤษ | {process.env.appName}
            </h1>
            <div>
              ยินดีต้อนรับเข้าสู่ CanCount
              <br />
              หากคุณต้องการเริ่มใช้งาน
              คุณสามารถคัดลอกบทความและวางข้อความลงในช่องนับคำที่ปรากฎด้านล่างนี้
              <br />
              หรือเลือกไฟล์ (.pdf, .docx, .txt) จากอุปกรณ์ของท่าน
              เพียงเท่านี้ก็พร้อมทราบจำนวนคำและความหนาแน่นของคำหลักแบบแม่นยำได้ทันที!!
            </div>
          </div>

          {showAffiliate && affiliates !== null && (
            <AffiliateCarousel
              affiliates={affiliates}
              onClick={onAffiliateClick}
            />
          )}

          {!showAffiliate && (
            <AdSenseBanner
              adSlotId={`${process.env.adsenseAdSlotId}`}
              adUnitId={`${process.env.adsenseUnitId}`}
            />
          )}

          <form>
            <div id="input-container" className="px-2 py-1 position-relative">
              <div
                id="loader"
                className={`${
                  styles["loader-dimer"]
                } position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center ${
                  isDictionaryLoading ? "visible" : "invisible"
                }`}
              >
                <div
                  className={`spinner-grow text-info float-center ${styles["loader-size"]}`}
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              </div>
              <div className="py-1 w-100 d-flex flex-row-reverse">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <DocumentFileSelector onContentReady={onContentReady} />
                  <button
                    id="clear-content-btn"
                    className="btn btn-block btn-danger"
                    type="button"
                    onClick={onClearTextInputClick}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                </div>
              </div>
              <div>
                <textarea
                  id="content-text-input"
                  className="form-control"
                  rows="10"
                  maxLength="500000"
                  placeholder="เขียนหรือวางข้อความของคุณที่นี่"
                  value={content}
                  onChange={onTextInputChange}
                />
              </div>
            </div>
            <div className="p-2 pt-1 pb-3 row g-2">
              <WordCountInfoPanel
                id="word_counter"
                title="จำนวนคำ"
                value={wordCounter.wordCount}
                unit="คำ"
              />
              <WordCountInfoPanel
                id="word-counter"
                title="จำนวนตัวอักษร"
                value={wordCounter.charCount}
                unit="ตัวอักษร"
                tooltip="รวมช่องว่าง"
              />
              <WordCountInfoPanel
                id="char-counter"
                title="เวลาที่ใช้ในการอ่าน"
                value={wordCounter.readingTime}
                unit={`นาที`}
                tooltip={`${READING_SPEED_WPM} คำ/นาที`}
              />
              <WordCountInfoPanel
                id="word_counter"
                title="เวลาที่ใช้ในการพูด"
                value={wordCounter.speakingTime}
                unit={`นาที`}
                tooltip={`${SPEAKING_SPEED_WPM} คำ/นาที`}
              />
            </div>
          </form>
          <div className="px-3 py-1 row g-1">
            <div className="col-sm-6">
              <p className="h5">ความหนาแน่นของคำ</p>
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="word-exclude-stop-words"
                  onChange={onExcludeStopwirdSwitchChange}
                  checked={wordDensityExcludeStopWords}
                />
                <label
                  className="form-check-label"
                  htmlFor="wd-exclude-stop-words"
                >
                  ละเว้นคำเชื่อมประโยค
                </label>
              </div>
            </div>
          </div>
          <WordDensityTable wordDensityList={wordCounter.wordDensity} />

          <div className="px-3 pt-2 pb-1">
            <p className="h5">คำที่พบในข้อความ</p>
          </div>
          <div
            id="word-list-container"
            className={`p-2 mx-2 my-1 rounded-3 notranslate ${styles["highlight-container"]}`}
          >
            {wordCounter.words.map((word) => {
              return (
                <>
                  <WordBadge word={word} />
                </>
              );
            })}
          </div>
        </div>

        <ul className="mt-1 mb-2 list-group" id="info-accordion">
          <li key="intro" className="list-group-item">
            <h2 className="h5">เครื่องมือนับจำนวนคำภาษาไทย CanCount</h2>
            <p className="m-0">
              เครื่องมือนับคำออนไลน์ CanCount
              เครื่องมือนับคำที่พัฒนาขึ้นมาเพื่อการนับจำนวนคำอย่างแม่นยำที่สุด
              ทั้งภาษาอังกฤษและภาษาไทย สามารถนับได้ทั้งจำนวนคำ จำนวนตัวอักษร
              คำนวณเวลาที่ใช้ในการอ่านและเวลาที่ใช้ในการพูดได้อย่างรวดเร็ว
            </p>
          </li>
          <li key="why-cancount" className="list-group-item">
            <p className="h5">ทำไมคุณถึงควรใช้ CanCount</p>
            <p className="m-0">
              โดยปกติแล้วหากคุณใช้งานโปรแกรม Microsoft Word
              คุณสามารถทราบจำนวนคำของบทความได้อยู่แล้ว แต่หากคุณไม่มีโปรแกรม
              Microsoft Word หรือต้องการนับคำจากอุปกรณ์มือถือ
              เว็บไซต์นับคำถือเป็นทางเลือกหนึ่งในการช่วยนับคำ
              แต่เว็บไซต์ส่วนใหญ่ไม่สามารถนับจำนวนคำภาษาไทยได้จริง CanCount
              จึงเป็นตัวเลือกที่เหมาะสมกับงานของท่าน
            </p>
          </li>
          <li key="why-word-count" className="list-group-item">
            <p className="h5">การนับจำนวนคำสำคัญอย่างไร</p>
            <p className="m-0">
              การนับจำนวนคำมีส่วนช่วยในทุกๆงานเขียนของคุณ
              ให้งานเขียนของคุณมีปริมาณของจำนวนคำที่ถูกต้องและเหมาะสมกับงานที่คุณต้องทำ
              เช่น บทความเพื่อการทำ SEO นักเขียนหรือนักประพันธ์
              ตลอดไปจนถึงอาจารย์ นักเรียนและนักศึกษา CanCount
              ก็สามารถช่วยให้การนับจำนวนคำของคุณง่ายขึ้นได้
            </p>
          </li>
          <li key="feature" className="list-group-item">
            <p className="h5">
              วิเคราะห์ความหนาแน่นของคำหลัก เพิ่มประสิทธิภาพ SEO
            </p>
            <p className="m-0">
              นอกไปจากนี้ CanCount ยังสามารถคำนวณความหนาแน่นของคำหลักในบทความ
              เพื่อช่วยให้คุณสามารถประเมินและปรับเปลี่ยนปริมาณคำหลักในบทความได้อย่างเหมาะสม
              ส่งผลให้สามารถทำ SEO
              สำหรับบทความนั้นๆได้อย่างมีประสิทธิภาพมากที่สุด{" "}
            </p>
          </li>
          <li key="privacy" className="list-group-item">
            <p className="h5">รับรองความปลอดภัยของทุกเนื้อหา</p>
            <p className="m-0">
              CanCount
              เราให้ความสำคัญกับความเป็นส่วนตัวของคุณและเนื้อหา/บทความของคุณ
              ระบบของเราจะไม่บันทึกหรือจัดเก็บเนื้อหาไว้ใช้ในภายหลัง
              หรือนำเอาเนื้อหา/บทความดังกล่าวนี้ไปเผยแพร่ต่อสาธารณะอย่างแน่นอน
            </p>
          </li>
          <li key="free" className="list-group-item">
            <p className="h5">ใช้งานได้ฟรี ไม่มีข้อจำกัด</p>
            <p className="m-0">
              คุณสามารถนับจำนวนคำ
              ทั้งภาษาไทยและภาษาอังกฤษได้ฟรีและไม่จำกัดจำนวนครั้ง
              พร้อมผลลัพธ์ที่แม่นยำ
            </p>
          </li>
          <li key="no-installation" className="list-group-item">
            <p className="h5">
              สะดวก สบาย ใช้งานได้โดยไม่ต้องติดตั้งโปรแกรมใด ๆ
            </p>
            <p className="m-0">
              เพราะเราเข้าใจว่าทุกเวลาของคุณมีค่า CanCount
              ให้คุณตรวจนับจำนวนคำและตัวอักษร ภาษาไทยและภาษาอังกฤษ
              ที่มีผลอย่างแม่นยำได้โดยไม่ต้องติดตั้งส่วนขยายใด ๆ เพิ่มเติม
            </p>
          </li>
        </ul>
      </main>
    </div>
  );
}
