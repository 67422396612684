import useSWR from "swr";

import { captureException } from "@sentry/nextjs";

const thDictionaryFetcher = (...args) =>
  fetch(...args)
    .then((res) => res.text())
    .then((text) => {
      try {
        const dicts = text.split("\n");
        let words = dicts.flat();
        words = [...new Set(words)];
        console.log(
          "Load th dictionary success with " + words.length + " words"
        );
        return words;
      } catch (e) {
        return new Promise.error(e);
      }
    })
    .catch((e) => {
      captureException(e);
      return e;
    });

export function useThDictionary() {
  const { data, error } = useSWR(
    "/dictionary/th/dictionary.txt",
    thDictionaryFetcher,
    {
      revalidateOnFocus: false,
      errorRetryInterval: 1000,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      loadingTimeout: 10000,
    }
  );
  return {
    words: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useThStopWords() {
  const { data, error } = useSWR(
    "/dictionary/th/stopwords.txt",
    thDictionaryFetcher,
    {
      revalidateOnFocus: false,
      errorRetryInterval: 1000,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      loadingTimeout: 10000,
    }
  );
  return {
    words: data,
    isLoading: !error && !data,
    isError: error,
  };
}
