import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { firebase as firebaseApp } from "./firebase";

const AFFILIATE_CONFIG_KEY = "affiliate_config";
const AFFILIATE_DISPLAY_CONFIG_KEY = "affiliate_display_config";

let frConfig;

const AFFILIATE_DISPLAY_CONFIG = {
  force_display: false,
  display_rate: 0.2,
};

function remoteConfig(next, sync = false) {
  if (frConfig === undefined) {
    frConfig = getRemoteConfig(firebaseApp);
    frConfig.settings.minimumFetchIntervalMillis =
      process.env.remoteConfigInterval * 3600000;
    frConfig.defaultConfig = {
      affiliate_config: null,
      affiliate_display_config: JSON.stringify(AFFILIATE_DISPLAY_CONFIG),
    };
    if (sync) {
      console.log("Start fetch remote config");
      fetchAndActivate(frConfig)
        .then(() => {
          console.log("Fetch remote config success");
          if (next != undefined && next != null) {
            next();
          }
        })
        .catch((err) => {
          console.log("Fetch remote config error", err);
        });
    } else {
      next();
    }
  } else {
    if (next != undefined && next != null) {
      next();
    }
  }
}

function getAffiliateConfig() {
  let frValue = null;
  try {
    const value = getValue(frConfig, AFFILIATE_CONFIG_KEY)._value;
    if (value == "null") {
      frValue = null;
    } else {
      frValue = JSON.parse(value);
    }
  } catch (e) {
    console.log(e);
    frValue = null;
  }

  if (frValue !== null) {
    if (frValue.constructor !== Object) {
      console.log("AffiliateConfig is not an object set value to null");
      frValue = null;
    } else {
      let keys = Object.keys(frValue);
      let rn = Math.floor(Math.random() * keys.length);
      frValue = frValue[keys[rn]];
      frValue = frValue.sort(() => Math.random() - 0.5);
    }
  }

  return frValue;
}

function getAffiliateDisplayConfig() {
  let frValue = null;
  try {
    const value = getValue(frConfig, AFFILIATE_DISPLAY_CONFIG_KEY)._value;
    console.log("getAffiliateDisplayConfig", value);
    if (value == "null") {
      frValue = null;
    } else {
      frValue = JSON.parse(value);
    }
  } catch (e) {
    console.log(e);
    frValue = null;
  }
  return frValue;
}

export {
  remoteConfig,
  getAffiliateConfig,
  getAffiliateDisplayConfig,
  AFFILIATE_DISPLAY_CONFIG,
};
