import WordDensityRow from "../components/WordDensityRow";

const WordDensityTable = ({ wordDensityList }) => (
  <div id="word-density-container" className="p-2 mx-2 my-1 table-responsive">
    <table
      id="word-density-table-head"
      className="table table-striped table-hover"
    >
      <thead>
        <tr>
          <th className="col-md-1" scope="col">
            #
          </th>
          <th className="col-md-5" scope="col">
            คำ
          </th>
          <th className="col-md-3" scope="col">
            จำนวน(คำ)
          </th>
          <th className="col-md-3" scope="col">
            ความหนาแน่น(%)
          </th>
        </tr>
      </thead>
      <tbody id="word-density-table-body" className="notranslate">
        {wordDensityList.map((wordDensity) => {
          return (
            <>
              <WordDensityRow item={wordDensity} />
            </>
          );
        })}
      </tbody>
    </table>
  </div>
);

export default WordDensityTable;
