const _RE_TCC = `เc็c
เcctาะ
เccีtยะ
เccีtย(?=[เ-ไก-ฮ]|$)
เcc็c
เcิc์c
เcิtc
เcีtยะ?
เcืtอะ?
เc[ิีุู]tย(?=[เ-ไก-ฮ]|$)
เctา?ะ?
cัtวะ
c[ัื]tc[ุิะ]?
c[ิุู]์
c[ะ-ู]t
c็
ct[ะาำ]?
แc็c
แcc์
แctะ
แcc็c
แccc์
โctะ
[เ-ไ]ct`
  .replaceAll("c", "[ก-ฮ]")
  .replaceAll("t", "[่-๋]?")
  .split("\n");

const _PAT_TCC = new RegExp(_RE_TCC.join("|"));

function* tcc(text) {
  if (typeof text !== "string") {
    return "";
  }
  let lenText = text.length;
  let p = 0;

  while (p < lenText) {
    let m = _PAT_TCC.exec(text.slice(p, lenText));
    let n;
    if (m !== null && m.index == 0) {
      n = m.index + m[0].length;
    } else {
      n = 1;
    }
    yield text.slice(p, p + n);
    p = p + n;
  }
}

export function tccPos(text) {
  let pSet = new Set();
  if (typeof text !== "string") {
    return pSet;
  }
  let p = 0;
  const tccGen = tcc(text);
  let w = tccGen.next();
  while (!w.done) {
    p = p + w.value.length;
    pSet.add(p);
    w = tccGen.next();
  }
  return pSet;
}
