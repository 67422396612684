import { useRef } from "react";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const DocumentFileSelector = ({ onContentReady }) => {
  const pdfFileSelecterRef = useRef();
  const docxFileSelecterRef = useRef();
  const textFileSelecterRef = useRef();

  const onFileTypeSelect = (e) => {
    e.preventDefault();
    console.log("onFileTypeSelect", e);
    e.target.id;
    if (e.target.id == "pdf-file-selector") {
      pdfFileSelecterRef.current.value = "";
      pdfFileSelecterRef.current.click();
    } else if (e.target.id == "docx-file-selector") {
      docxFileSelecterRef.current.value = "";
      docxFileSelecterRef.current.click();
    } else if (e.target.id == "text-file-selector") {
      textFileSelecterRef.current.value = "";
      textFileSelecterRef.current.click();
    }
  };

  const publishContentReady = (event, content) => {
    if (content.length > 50000) {
      content = content.substring(0, 50000);
    }
    onContentReady(event, content);
  };

  const onPdfFileChanged = (event) => {
    console.log("onPdfFileChanged", event);
    const fileReader = new FileReader();
    const { files } = event.target;
    if (files.length > 0) {
      fileReader.readAsArrayBuffer(files[0]);
      fileReader.onload = (e) => {
        console.log("PdfFile:fileReader.onload", e);
        var typedarray = new Uint8Array(e.target.result);
        const loadingTask = pdfjsLib.getDocument(typedarray);
        loadingTask.promise.then((pdf) => {
          var maxPages = pdf.numPages;
          var countPromises = [];
          for (var j = 1; j <= maxPages; j++) {
            var page = pdf.getPage(j);
            countPromises.push(
              page.then(function (page) {
                var textContent = page.getTextContent();
                return textContent.then(function (text) {
                  return text.items
                    .map(function (s) {
                      return s.str;
                    })
                    .join("");
                });
              })
            );
          }
          Promise.all(countPromises)
            .then(function (texts) {
              return texts.join("\n");
            })
            .then(function (content) {
              publishContentReady(event, content);
            });
        });
      };
    }
  };

  const onDocxFileChanged = (event) => {
    console.log("onDocxFileChanged", event);
    const fileReader = new FileReader();
    const { files } = event.target;
    if (files.length > 0) {
      fileReader.readAsBinaryString(files[0], "UTF-8");
      fileReader.onload = (e) => {
        console.log("fileReader.onload", e);
        let content = e.target.result;
        let zip = new PizZip(content);
        let doc = new Docxtemplater().loadZip(zip);
        content = doc.getFullText();
        publishContentReady(event, content);
      };
    }
  };

  const onTextFileChanged = (event) => {
    console.log("onTextFileChanged", event);
    const fileReader = new FileReader();
    const { files } = event.target;
    if (files.length > 0) {
      fileReader.readAsText(files[0], "UTF-8");
      fileReader.onload = (e) => {
        console.log("fileReader.onload", e);
        const content = e.target.result;
        publishContentReady(event, content);
      };
    }
  };

  return (
    <div className="btn-group" role="group">
      <button
        id="btn-file-type-group"
        type="button"
        className="btn btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        ดึงข้อมูลจากไฟล์
      </button>
      <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <li>
          <a
            id="pdf-file-selector"
            className="dropdown-item"
            onClick={onFileTypeSelect}
          >
            .pdf
          </a>
        </li>
        <li>
          <a
            id="docx-file-selector"
            className="dropdown-item"
            onClick={onFileTypeSelect}
          >
            .docx
          </a>
        </li>
        <li>
          <a
            id="text-file-selector"
            className="dropdown-item"
            onClick={onFileTypeSelect}
          >
            .txt
          </a>
        </li>
      </ul>
      <input
        id="pdf-file-selector-input"
        ref={pdfFileSelecterRef}
        type="file"
        style={{ display: "none" }}
        accept=".pdf"
        onChange={onPdfFileChanged}
      />
      <input
        id="docx-file-selector-input"
        ref={docxFileSelecterRef}
        type="file"
        style={{ display: "none" }}
        accept=".docx"
        onChange={onDocxFileChanged}
      />
      <input
        id="text-file-selector-input"
        ref={textFileSelecterRef}
        type="file"
        style={{ display: "none" }}
        accept=".txt"
        onChange={onTextFileChanged}
      />
    </div>
  );
};

export default DocumentFileSelector;
