import Trie from "../common/Trie";
import { tccPos } from "./tcc";
import { heapify, heappush, heappop } from "../common/heapq";

const _PAT_NONTHAI = /(?<x>)[-a-zA-Z]+|\d+([,\.]\d+)*|[ \t]+|\r?\n/;
const _PAT_THAI_TWOCHARS = new RegExp("[ก-ฮ]{,2}$");
const _MAX_GRAPH_SIZE = 50;

function* bfsPathGraph(graph, start, goal) {
  let queue = [{ vertex: start, path: [start] }];
  while (queue === undefined || queue.length > 0) {
    let node = queue.shift();
    if (graph[node.vertex] !== undefined) {
      for (let pos of graph[node.vertex]) {
        if (pos === goal) {
          yield node.path.concat(pos);
        } else {
          queue.push({ vertex: pos, path: node.path.concat(pos) });
        }
      }
    }
  }
}

function* oneCut(text, customDict) {
  const graph = {};
  let graphSize = 0;
  const validPoss = tccPos(text);
  let lenText = text.length;
  const posList = heapify([0]);
  let endPos = 0;
  while (posList[0] < lenText) {
    let beginPos = heappop(posList);
    for (let word of customDict.prefixes(text.slice(beginPos, lenText))) {
      let endPosCandidate = beginPos + word.length;
      if (validPoss.has(endPosCandidate)) {
        if (!(beginPos in graph)) {
          graph[beginPos] = [];
        }
        graph[beginPos].push(endPosCandidate);
        graphSize = graphSize + 1;
        if (!posList.includes(endPosCandidate)) {
          heappush(posList, endPosCandidate);
        }
        if (graphSize > _MAX_GRAPH_SIZE) {
          break;
        }
      }
    }

    let lenPosList = posList.length;

    if (lenPosList === 1) {
      const endPosCandidatesGen = bfsPathGraph(graph, endPos, posList[0]);
      graphSize = 0;
      let endPosCandidates = endPosCandidatesGen.next();
      for (let pos of endPosCandidates.value) {
        yield text.slice(endPos, pos);
        endPos = pos;
      }
    } else if (lenPosList === 0) {
      let m = _PAT_NONTHAI.exec(text.slice(beginPos, lenText));
      if (m != null && m.index == 0) {
        endPos = beginPos + (m.index + m[0].length);
      } else {
        endPos = null;
        for (let pos = beginPos; pos < lenText; pos++) {
          if (validPoss.has(pos)) {
            var prefix = text.slice(pos, lenText);
            var words = [];
            for (let word of customDict.prefixes(prefix)) {
              if (
                validPoss.has(pos + word.length) &&
                _PAT_THAI_TWOCHARS.exec(word) === null
              ) {
                words.push(word);
              }
            }

            if (words.length > 0) {
              endPos = pos;
              break;
            }

            m = _PAT_NONTHAI.exec(prefix);
            if (m !== null && m.index == 0) {
              endPos = pos;
              break;
            }
          }
        }
        if (endPos == null) {
          endPos = lenText;
        }
      }
      if (!(beginPos in graph)) {
        graph[beginPos] = [];
      }
      graph[beginPos].push(endPos);
      graphSize = graphSize + 1;
      yield text.slice(beginPos, endPos);
      heappush(posList, endPos);
    }
  }
}

class NewMmTokenizer {
  constructor(words) {
    this.dictionary = new Trie(words);
  }

  tokenize(text) {
    const dict = this.dictionary;

    if (typeof text !== "string") {
      return [];
    }

    text = text.replaceAll("\n", " ");

    const textParts = text.split(" ");

    let tokens = [];
    for (let textPart of textParts) {
      tokens = tokens.concat([...oneCut(textPart, dict)]);
      tokens.push(" ");
    }

    tokens = tokens.filter((token) => token.length > 0);

    return tokens;
  }
}

export default NewMmTokenizer;
