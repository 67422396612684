const WordCountInfoPanel = ({ id, title, value, unit, tooltip }) => {
  let tooltipProps = {};
  if (tooltip != null) {
    tooltipProps["data-bs-toggle"] = "tooltip";
    tooltipProps["data-bs-placement"] = "bottom";
    tooltipProps["title"] = tooltip;
  }
  return (
    <div className="col-sm-3">
      <div className="card h-100 text-center" {...tooltipProps}>
        <div id={`${id}-card`} className="card-body">
          <p className="card-text mb-1">{title}</p>
          <p className="h5 card-title mb-1">{value}</p>
          <p className="card-text mb-0">{unit}</p>
        </div>
      </div>
    </div>
  );
};

export default WordCountInfoPanel;
