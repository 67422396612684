class Trie {
  constructor(words) {
    this.words = new Set(words || []);
    this.root = new Trie.Node();
    for (var i = 0; i < words.length; i++) {
      this.add(words[i]);
    }
  }

  add(word) {
    word = word.trim();
    this.words.add(word);
    var cur = this.root;
    for (var i = 0; i < word.length; i++) {
      var child = cur.children[word[i]];
      if (child === undefined) {
        child = new Trie.Node();
        cur.children[word[i]] = child;
      }
      cur = child;
    }
    cur.end = true;
  }

  remove(word) {
    if (!this.words.has(word)) {
      return;
    }
    this.words.delete(word);
    var parent = this.root;
    data = [];
    for (var i = 0; i < word.length; i++) {
      child = parent.children[word[i]];
      data.push({ parent: parent, child: child, ch: word[i] });
      parent = child;
    }
    child.end = false;
    for (const node of data) {
      if (node.child.end || node.child.children != undefined) {
        break;
      }
      delete node.parent.children[node.ch];
    }
  }

  prefixes(text) {
    var res = [];
    var cur = this.root;
    for (var i = 0; i < text.length; i++) {
      var node = cur.children[text[i]];
      if (node === undefined) {
        break;
      }
      if (node.end) {
        res.push(text.slice(0, i + 1));
      }
      cur = node;
    }
    return res;
  }

  contain(key) {
    return key in this.words;
  }

  len() {
    return this.words.size;
  }
}

Trie.Node = class {
  constructor() {
    this.end = false;
    this.children = {};
  }
};

export default Trie;
