const KEEP_CONTENT_CHAR_REGEX = /[^A-Za-z0-9ก-๙]/;
const TRIM_MULTIPLE_SPACE_REGEX = /\s+/;

export function keepContentChar(message) {
  return message.replace(KEEP_CONTENT_CHAR_REGEX, "");
}

export function trimMultipleSpace(message) {
  return message.replace(TRIM_MULTIPLE_SPACE_REGEX, " ");
}
