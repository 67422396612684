const WordDensityRow = ({ item }) => (
  <tr key={item}>
    <th id="index" scope="row">
      {item.index}
    </th>
    <td id="word">{item.word}</td>
    <td id="count">{item.count}</td>
    <td id="density">{item.density}</td>
  </tr>
);

export default WordDensityRow;
