const AdSenseBanner = ({ adUnitId, adSlotId }) => {
  return (
    <div className="p-1" align="center">
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
        }}
        data-ad-client={`${adUnitId}`}
        data-ad-slot={`${adSlotId}`}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default AdSenseBanner;
