import Image from "next/image";

import { ExternalImageLoader } from "../lib/imageLoader";

const AffiliateBanner = ({ affiliate, onClick }) => {
  return (
    <div
      className="p-0 w-100 d-flex justify-content-center"
      style={{ background: affiliate.background }}
      key={affiliate.key}
    >
      <a
        href={affiliate.affiliateUrl}
        target="_blank"
        rel="noreferrer"
        className="p-0 position-relative d-flex justify-content-center"
      >
        {affiliate.customBannerImageUrl != "" && (
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
            <Image
              src={affiliate.customBannerImageUrl}
              loader={ExternalImageLoader}
              alt="advertising-impression"
              width={1}
              height={1}
              objectFit="none"
              unoptimized={true}
              data-key={affiliate.key}
            />
          </div>
        )}
        <Image
          src={affiliate.bannerImageUrl}
          loader={ExternalImageLoader}
          className="img-fluid"
          alt="advertising"
          width={affiliate.bannerImageWidth}
          height={affiliate.bannerImageHeight}
          layout="intrinsic"
          unoptimized={true}
          onClick={onClick}
          data-key={affiliate.key}
        />
      </a>
    </div>
  );
};

export default AffiliateBanner;
