import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import AffiliateBanner from "../components/AffiliateBanner";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/components/AffiliateCarousel.module.css";

const AffiliateCarousel = ({ affiliates, onClick }) => {
  SwiperCore.use(
    affiliates.length > 1 ? [Autoplay, Navigation, Pagination] : []
  );
  let navigationStyle =
    affiliates.length > 1
      ? { color: "white" }
      : { color: "white", display: "none" };
  return (
    <div className="mb-3">
      <p className="mx-1 my-1 font-italic">Advertisements</p>
      <Swiper
        id="swiper"
        loop
        autoplay={{
          delay: "5000",
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        pagination={{
          clickable: true,
          bulletClass: `swiper-pagination-bullet ${styles["bullet-class"]}`,
          bulletActiveClass: `swiper-pagination-bullet-active ${styles["bullet-class-active"]}`,
        }}
      >
        {affiliates.map((affiliate) => {
          return (
            <SwiperSlide key={affiliate.key}>
              <AffiliateBanner affiliate={affiliate} onClick={onClick} />
            </SwiperSlide>
          );
        })}
        <div className="swiper-button-next" style={navigationStyle}></div>
        <div className="swiper-button-prev" style={navigationStyle}></div>
      </Swiper>
    </div>
  );
};

export default AffiliateCarousel;
