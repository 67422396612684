const WORD_DENSITY_EXCLUDE_STOP_WORDS = "word_density_exclude_stop_words";

function getLocalConfig(configKey) {
  if (window !== undefined && window !== null) {
    return localStorage.getItem(configKey);
  } else {
    return null;
  }
}

function setLocalConfig(configKey, value) {
  if (window !== undefined && window !== null) {
    localStorage.setItem(configKey, value);
  }
}

function getBooleanLocalConfig(configKey, defaultValue) {
  let value = getLocalConfig(configKey);
  console.log("getBooleanLocalConfig", value);
  if (value != null) {
    return value === "true";
  } else {
    return defaultValue;
  }
}

function setBooleanLocalConfig(configKey, value) {
  setLocalConfig(configKey, value.toString());
}

export {
  getBooleanLocalConfig,
  setBooleanLocalConfig,
  WORD_DENSITY_EXCLUDE_STOP_WORDS,
};
